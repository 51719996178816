import { Component, Input } from "@angular/core";

@Component({
  selector: "app-link-redirect",
  templateUrl: "./link-redirect.component.html",
  styleUrl: "./link-redirect.component.less",
})
export class LinkRedirectComponent {
  @Input("Title") Title!: string;
  @Input("Link") Link!: string;
  @Input("redirectData") redirectData: string | null = null;

  public get redirectLink(): string {
    return this.redirectData !== null ? `${this.Link}?data=${this.redirectData}` : "";
  }

  public redirect(): void {
    window.open(this.redirectLink, "_blank");
  }
}
