import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { NgModule, isDevMode, LOCALE_ID } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  MsalModule,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MsalService,
  MsalGuard,
  MsalBroadcastService,
  MsalRedirectComponent,
  MsalInterceptor,
  MSAL_INTERCEPTOR_CONFIG,
} from "@azure/msal-angular";
import { registerLocaleData } from "@angular/common";
import localeNl from "@angular/common/locales/nl";
registerLocaleData(localeNl);

// import { TableVirtualScrollModule } from "ng-table-virtual-scroll";
import { AppRoutingModule } from "./app-routing.module";
import { MSALInstanceFactory, MSALGuardConfigFactory, MSALInterceptorConfigFactory } from "./classes/MSAL";
import { BadgeFiltersComponent } from "./components/badge-filters/badge-filters";
import { DialogComponent } from "./components/dialog/dialog.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { MenuComponent } from "./components/menu/menu";
import { EnergyConsultComponent } from "./components/energy-consult/energy-consult.component";
import { LanguageModule } from "./modules/language.module";
import { MaterialModule } from "./modules/material.module";
import { LocalDatePipe } from "./pipes/date.pipe";
import { ChooseRoleComponent } from "./screens/choose-role/choose-role.component";
import { AllQuestionnairesComponent } from "./screens/content/all-questionnaires/all-questionnaires.component";
import { QuestionnaireComponent } from "./screens/content/all-questionnaires/questionnaire/questionnaire.component";
import { CoachComponent } from "./screens/content/coach/coach.component";
import { CoachEnergyConsultComponent } from "./screens/content/coach/coach-energy-consult/coach-energy-consult.component";
import { ContentComponent } from "./screens/content/content";
import { CoordinatorComponent } from "./screens/content/coordinator/Coordinator.component";
import { NewCoachComponent } from "./screens/content/coordinator/new-coach/new-coach.component";
import { UserAllComponent } from "./screens/content/coordinator/user/all/all.component";
import { UsersComponent } from "./screens/content/coordinator/user/users.component";
import { ResidentComponent } from "./screens/content/resident/Resident.component";
import { DashboardComponent } from "./screens/content/shared/dashboard/dashboard";
import { ProfileComponent } from "./screens/content/shared/profile/profile.component";
import { Error_403Component } from "./screens/error/403/403";
import { Error_404Component } from "./screens/error/404/404";
import { ErrorComponent } from "./screens/error/error";
import { NewEnergyConsultComponent } from "./screens/guest/new-energy-consult/new-energy-consult.component";
import { NewQuestionnaireComponent } from "./screens/new-questionnaire/new-questionnaire.component";
import { RootComponent } from "./screens/root/root";
import { ApiService } from "./services/api.service";
import { ApplicationService } from "./services/application.service";
import { GraphQLService } from "./services/graphql.service";
import { EnergyConsultService } from "./services/energy-consult.service";
import { UserService } from "./services/user.service";
import { ReportComponent } from "./screens/report/report.component";
import { SnackbarService } from "./services/snackbar.service";
import { LoggedOutComponent } from "./screens/guest/logged-out/logged-out.component";
import { HasRoleGaurd } from "./guards/hasRole";
import { ViewReportComponent } from "./screens/view-report/view-report.component";
import { FAQComponent } from "./screens/faq/faq.component";
import { TableComponent } from "./components/table/table.component";
import { LoginFailedComponent } from "./screens/login-failed/login-failed.component";
import { QuestionnaireFormComponent } from "./components/questionnaire-form/questionnaire-form.component";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { CustomPaginator } from "./components/table/CustomPaginator";
import { RoleDirective } from "./directives/role-directive";
import { InputErrorComponent } from "./components/error/error.component";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { StateProgressBarComponent } from "./components/state-progress-bar/state-progress-bar.component";
import { DragDropModule } from "@angular/cdk/drag-drop";

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
// import { MomentDateAdapter } from "@angular/material-moment-adapter";

import { MatDatepickerModule } from "@angular/material/datepicker";

import { AsyncActionButtonComponent } from "./components/async-action-button/async-action-button.component";
import { CoachService } from "./services/coach.service";
import { CoachEnergyConsultService } from "./services/coach-energy-consult.service";
import { TimeRangePickerComponent } from "./components/time-range-picker/time-range-picker.component";
import { RetailersComponent } from "./screens/content/coordinator/retailers/retailers.component";
import { NewRetailerComponent } from "./screens/content/coordinator/new-retailer/new-retailer.component";
import { DashboardRetailerComponent } from "./screens/content/shared/dashboard/dashboard-retailer/dashboard-retailer.component";
import { RetailerComponent } from "./screens/content/retailer/retailer.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { GlobalLoadingComponent } from "./components/global-loading/global-loading.component";
import { ReplacementClaimNewComponent } from "./screens/content/coach/replacement-claim-new/replacement-claim-new.component";
import { ReplacementClaimOverviewOpenComponent } from "./screens/content/retailer/replacement-claim-overview-open/replacement-claim-overview-open.component";
import { ReplacementClaimOverviewOrderedComponent } from "./screens/content/retailer/replacement-claim-overview-ordered/replacement-claim-overview-ordered.component";
import { ReplacementClaimOverviewDeliveredComponent } from "./screens/content/retailer/replacement-claim-overview-delivered/replacement-claim-overview-delivered.component";
import { ReplacementClaimOverviewComponent } from "./components/replacement-claim-overview/replacement-claim-overview.component";
import { NgxScannerQrcodeModule } from "ngx-scanner-qrcode";
import { QrScannerComponent } from "./components/qr-scanner/qr-scanner.component";
import { RadioImageComponent } from "./components/radio-image/radio-image.component";
import { NewEnergyConsultExistingResidentComponent } from "./screens/guest/new-energy-consult-existing-resident/new-energy-consult-existing-resident.component";
import { RetailerLandingComponent } from "./screens/retailer-landing/retailer-landing.component";
import { QrcodeLandingComponent } from "./screens/qrcode-landing/qrcode-landing.component";
import { RetailerPayOutOverviewComponent } from "./components/retailer-pay-out-overview/retailer-pay-out-overview.component";
import { ReplacementClaimScheduleComponent } from "./screens/content/retailer/replacement-claim-schedule/replacement-claim-schedule.component";
import { ReplacementClaimScheduleFormComponent } from "./components/replacement-claim-schedule-form/replacement-claim-schedule-form.component";
import { ReplacementClaimDeliverFormComponent } from "./components/replacement-claim-deliver-form/replacement-claim-deliver-form.component";
import { RetailerPayoutComponent } from "./screens/content/retailer/retailer-payout/retailer-payout.component";
import { RetailerPayoutFormComponent } from "./components/retailer-payout-form/retailer-payout-form.component";
import { ReplacementClaimRejectComponent } from "./screens/content/retailer/replacement-claim-reject/replacement-claim-reject.component";
import { ReplacementClaimRejectFormComponent } from "./components/replacement-claim-reject-form/replacement-claim-reject-form.component";
import { ReplacementClaimDeliverComponent } from "./screens/content/retailer/replacement-claim-deliver/replacement-claim-deliver.component";
import { PaymentrequestNewComponent } from "./screens/content/retailer/paymentrequest-new/paymentrequest-new.component";
import { RetailerPaymentrequestNewComponent } from "./components/retailer-paymentrequest-new/retailer-paymentrequest-new.component";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { ReplacementClaimEditComponent } from "./screens/content/coach/replacement-claim-edit/replacement-claim-edit.component";
import { QrInputComponent } from "./components/qr-input/qr-input.component";
import { TimePickerComponent } from "./components/time-picker/time-picker.component";
import { SignatureInputComponent } from "./components/signature-input/signature-input.component";
import { TimeSlotsInputComponent } from "./components/time-slots-input/time-slots-input.component";
import { GoogleMapsModule } from "@angular/google-maps";
import { QuestionnaireFreshComponent } from "./screens/content/all-questionnaires/questionnaire-fresh/questionnaire-fresh.component";
import { QuestionEditComponent } from "./screens/content/all-questionnaires/question-edit/question-edit.component";
import { AreaActionsComponent } from "./screens/content/coordinator/area-actions/area-actions.component";
import { AreaActionsOverviewComponent } from "./components/area-actions-overview/area-actions-overview.component";
import { AreaActionsFormComponent } from "./components/area-actions-form/area-actions-form.component";
import { ModuleDirective } from "./directives/module-directive";
import { ExcludeModuleDirective } from "./directives/exclude-module-directive";
import { IconToggleComponent } from "./components/icon-toggle/icon-toggle.component";
import { MatRippleModule } from "@angular/material/core";
import { UnderConstructionComponent } from "./screens/under-construction/under-construction.component";
import { ApiErrorInterceptor } from "./interceptors/api-error.interceptor";
import { PostalcodePipe } from "./pipes/postalcode.pipe";
import { ConsultGridViewComponent } from "./components/consult-views/consult-grid-view/consult-grid-view.component";
import { ConsultListViewComponent } from "./components/consult-views/consult-list-view/consult-list-view.component";
import { ConsultMapViewComponent } from "./components/consult-views/consult-map-view/consult-map-view.component";
import { CustomTimepickerComponent } from "./components/custom-timepicker/custom-timepicker.component";
import { TimepickerDialogComponent } from "./components/timepicker-dialog/timepicker-dialog.component";
import { MailDataTimelineComponent } from "./mail-data-timeline/mail-data-timeline.component";
import { DashboardStatisticsComponent } from "./screens/content/shared/dashboard-statistics/dashboard-statistics.component";
import { ReportCategoriesComponent } from "./screens/content/coordinator/report-categories/report-categories.component";
import { StatisticTileComponent } from "./components/statistic-tile/statistic-tile.component";
import { ChartComponent } from "./components/chart/chart.component";
import { ConsultPlanningViewComponent } from "./components/consult-views/consult-planning-view/consult-planning-view.component";
import { LinkRedirectComponent } from "./components/link-redirect/link-redirect.component";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD-MM-YYYY",
  },
  display: {
    dateInput: "DD-MM-YYYY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

@NgModule({
  declarations: [
    RootComponent,
    ErrorComponent,
    Error_404Component,
    Error_403Component,
    ProfileComponent,
    RootComponent,
    HeaderComponent,
    NewEnergyConsultComponent,
    AllQuestionnairesComponent,
    QuestionnaireComponent,
    NewQuestionnaireComponent,
    MenuComponent,
    ContentComponent,
    CoachComponent,
    CoordinatorComponent,
    UsersComponent,
    UserAllComponent,
    EnergyConsultComponent,
    DialogComponent,
    ChooseRoleComponent,
    CoachEnergyConsultComponent,
    NewCoachComponent,
    ResidentComponent,
    QuestionnaireComponent,
    FooterComponent,
    DashboardComponent,
    LocalDatePipe,
    ReportComponent,
    BadgeFiltersComponent,
    LoggedOutComponent,
    TableComponent,
    ViewReportComponent,
    FAQComponent,
    RetailersComponent,
    LoginFailedComponent,
    QuestionnaireFormComponent,
    RoleDirective,
    InputErrorComponent,
    StateProgressBarComponent,
    AsyncActionButtonComponent,
    TimeRangePickerComponent,
    NewRetailerComponent,
    DashboardRetailerComponent,
    RetailerComponent,
    GlobalLoadingComponent,
    ReplacementClaimNewComponent,
    ReplacementClaimOverviewOpenComponent,
    ReplacementClaimOverviewOrderedComponent,
    ReplacementClaimOverviewDeliveredComponent,
    ReplacementClaimOverviewComponent,
    QrScannerComponent,
    RadioImageComponent,
    NewEnergyConsultExistingResidentComponent,
    RetailerLandingComponent,
    QrcodeLandingComponent,
    ReplacementClaimScheduleComponent,
    ReplacementClaimScheduleFormComponent,
    ReplacementClaimDeliverFormComponent,
    RetailerPayoutComponent,
    RetailerPayoutFormComponent,
    ReplacementClaimRejectComponent,
    ReplacementClaimRejectFormComponent,
    ReplacementClaimDeliverComponent,
    RetailerPayOutOverviewComponent,
    PaymentrequestNewComponent,
    RetailerPaymentrequestNewComponent,
    ReplacementClaimEditComponent,
    QrInputComponent,
    TimePickerComponent,
    SignatureInputComponent,
    ModuleDirective,
    ExcludeModuleDirective,
    TimeSlotsInputComponent,
    QuestionnaireFreshComponent,
    QuestionEditComponent,
    AreaActionsComponent,
    AreaActionsOverviewComponent,
    AreaActionsFormComponent,
    IconToggleComponent,
    UnderConstructionComponent,
    PostalcodePipe,
    ConsultGridViewComponent,
    ConsultListViewComponent,
    ConsultMapViewComponent,
    CustomTimepickerComponent,
    TimepickerDialogComponent,
    MailDataTimelineComponent,
    DashboardStatisticsComponent,
    ReportCategoriesComponent,
    StatisticTileComponent,
    ChartComponent,
    LinkRedirectComponent,
    ConsultPlanningViewComponent,
  ],
  imports: [
    GoogleMapsModule,
    CurrencyMaskModule,
    CommonModule,
    BrowserModule,
    MatDatepickerModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    MsalModule,
    FormsModule,
    ReactiveFormsModule,
    MatRippleModule,
    // TableVirtualScrollModule,
    LanguageModule,
    NgxMaterialTimepickerModule,
    DragDropModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    NgxScannerQrcodeModule,
  ],
  providers: [
    ApplicationService,
    ApiService,
    UserService,
    EnergyConsultService,
    GraphQLService,
    HasRoleGaurd,
    SnackbarService,
    CoachService,
    CoachEnergyConsultService,
    LocalDatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiErrorInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: MatPaginatorIntl,
      useClass: CustomPaginator,
    },
    {
      provide: [MAT_DATE_LOCALE],
      useValue: "nl-NL",
    },
    {
      provide: [LOCALE_ID],
      useValue: "nl-NL",
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [RootComponent, MsalRedirectComponent],
})
export class AppModule {
  constructor(public dateAdapter: DateAdapter<any>) {
    this.dateAdapter.setLocale(navigator.language);
  }
}
